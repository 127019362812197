import React from "react";
import { Navigation } from "../navigation/navigation";
import { StyleColumn } from "../style-column/style-column";
import * as _ from "lodash";
import { descriptions } from "../../assets/imgs/styles";

interface props {
    yourStyle: {
        first: string;
        second: string;
        third: string;
        fourth: string
    };
    name: string;
    selectedStyles: any;
}

const StyleDescription = ({style}: {style: string}) => {
    return <div>
        <h3>{style}</h3>
        <p>{descriptions[style]}</p>
    </div>;
};

export const Finish = ({yourStyle, name, selectedStyles}: props) => {
    const chunkedStyles = _.chunk(selectedStyles, Math.ceil(selectedStyles.length / 3));
    const uniqueStyles = _.uniq(Object.values(yourStyle));

    return <div className="App__wrapper">
        <div className="App__left">
            <h1>Mamy to!</h1>
            <p>
                {name}, opierając się na Twoich upodobaniach, okazało się,
                że Twoim głównym stylem jest styl <b>{uniqueStyles[0]}, z elementami
                stylu {uniqueStyles[1]} {uniqueStyles[2] && `i ${uniqueStyles[2]}`}</b>
            </p>
            <h2>Co je wyróżnia?</h2>

            <StyleDescription style={uniqueStyles[0]} />
            <StyleDescription style={uniqueStyles[1]} />
            { uniqueStyles[2] && <StyleDescription style={uniqueStyles[2]} /> }

            <Navigation previousStep='/styles'/>
        </div>
        <div className='App__right quiz-columns'>
            {chunkedStyles.map((chunk: any, index: number) =>
                <StyleColumn key={index} styles={chunk} chooseStyle={() => {
                }} selectedStyles={[]}/>
            )}
        </div>
    </div>;
};
