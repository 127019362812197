import './reset.css';
import './index.css';

import logo from './assets/imgs/logo.jpg';

import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import * as _ from 'lodash';
import * as serviceWorker from './serviceWorker';

import { determineStyle } from "./utils/determine-style";

import App from './components/app/app';
import { Places } from "./components/places/places";
import { Finish } from "./components/finish/finish";
import { Styles } from "./components/styles/styles";
import styles from "./assets/imgs/styles";

function scrollToTop() {
    return () => window.scrollTo(0, 0);
}

const Root = () => {
    const [place, setPlace] = useState();
    const [selectedStylesIds, setStyle] = useState<string[]>([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const handleSetPlace = (place: any) => {
        setPlace(place);
    };
    const handleSetStyle: (newStyle: string) => void = (newStyle: string) => {
        if (selectedStylesIds.indexOf(newStyle) === -1) {
            setStyle([...selectedStylesIds, newStyle]);
            return;
        }
        setStyle(selectedStylesIds.filter(style => style !== newStyle));
        return;
    };

    const yourStyle = determineStyle(selectedStylesIds);

    return <div className="App">
        <div className="logo">
            <img src={logo} alt=""/>
        </div>
        <Router>
            <Route
                onChange={scrollToTop()}
                exact
                path="/"
                render={
                    () => <App
                        privacyPolicy={privacyPolicy}
                        setPrivacyPolicy={setPrivacyPolicy}
                        newsletter={newsletter}
                        setNewsletter={setNewsletter}
                        name={name}
                        setName={setName}
                        email={email}
                        setEmail={setEmail}/>
                }/>
            <Route
                onChange={scrollToTop()}
                path="/places"
                render={() => <Places selectedPlace={place} choosePlace={handleSetPlace}/>}/>
            <Route
                onChange={scrollToTop()}
                path="/styles"
                render={
                    () => <Styles selectedStyles={selectedStylesIds}
                                  chooseStyle={handleSetStyle}
                                  email={email}
                                  name={name}
                                  privacyPolicy={privacyPolicy}
                                  newsletter={newsletter}
                                  yourStyle={yourStyle}/>
                }/>
            <Route
                onChange={scrollToTop()}
                path="/finish"
                render={() => <Finish yourStyle={yourStyle} name={name}
                                      selectedStyles={_.filter(styles, style => _.includes(selectedStylesIds, style.id))}/>}/>
        </Router>
    </div>;
};

ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
