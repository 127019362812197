import { wages } from "../assets/imgs/styles";

const transferStyles = (styles: any[]) => {
    return styles.map((style) => wages[style]).reduce((acc: { first: string[]; second: string[]; third: string[]; fourth: string[] }, item) => {
        acc.first = [...acc.first, item.first];
        acc.second = [...acc.second, item.second];
        acc.third = (item.third && [...acc.third, item.third]) || acc.third;
        acc.fourth = (item.fourth && [...acc.fourth, item.fourth]) || acc.fourth;
        return acc;
    }, {
        first: [],
        second: [],
        third: [],
        fourth: [],
    })
};

const countElements = (array: any[]) => {
    return array.reduce((acc: { [key: string]: number }, item) => {
        if (!acc[item]) {
            acc[item] = 1;
            return acc;
        }
        acc[item] += 1;
        return acc;
    }, {})
};

const findDominantValue = (obj: { [key: string]: number }) => {
    let values: any[][] = [];
    for (const key in obj) {
        values = [...values, [key, obj[key]]]
    }
    const sortedValues = values.sort((curr, prev) => (curr[1] >= prev[1]) ? -1 : 1)[0];
    return sortedValues && sortedValues[0];
};

export const determineStyle = (styles: any[]) => {
    const {first, fourth, second, third} = transferStyles(styles);

    return {
        first: findDominantValue(countElements(first)),
        second: findDominantValue(countElements(second)),
        third: findDominantValue(countElements(third)),
        fourth: findDominantValue(countElements(fourth)),
    }
};
