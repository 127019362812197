import california from './california.png';
import canada from './canada.png';
import greece from './greece.png';
import newYork from './new-york.png';
import norway from './norway.png';
import paris from './paris.png';
import rome from './rome.png';
import spain from './spain.png';

export default [
    { name: 'Kalifornia', src: california },
    { name: 'Kanada', src: canada },
    { name: 'Grecja', src: greece },
    { name: 'Nowy jork', src: newYork },
    { name: 'Norwegia', src: norway },
    { name: 'Paryż', src: paris },
    { name: 'Rzym', src: rome },
    { name: 'Hiszpania', src: spain },
];
