// Boho
import boho_midcentury_retro from './1_boho_mid_century_retro.jpg'
import boho_nowoczesny_rustykalny from './48_boho_nowoczesny_rustykalny.jpg'

// Nowoczesny
import nowoczesny_boho_midcentury_modern from './10_nowoczesny_boho_midcentury_modern.jpg'
import nowoczesny_skandynawski_boho from './54_nowoczesny_skandynawski_boho.jpg'
import nowoczesny_kolonialny_rustykalny from './15_nowoczesny_kolonialny_rustykalny.jpg'
import nowoczesny_minimalistyczny_skandynawski from './34_nowoczesny_minimalistyczny_skandynawski.jpg'
import nowoczesny_minimalistyczny_midcentury_modern from './44_nowoczesny_minimalistyczny_midcentury_modern.jpg'
import nowoczesny_industrialny_midcentury_modern from './45_nowoczesny_industrialny_midcentury_modern.jpg'
import nowoczesny_minimalistycznyny_klasyczny from './61_nowoczesny_minimalistycznyny_klasyczny.jpg'

// Skandynawski
import skandynawski_maksymalizm_boho_nowoczesny from './18_skandynawski_maksymalizm_boho_nowoczesny.jpg'
import skandynawski_rustykalny_kolonialny from './11_skandynawski_rustykalny_kolonialny.jpg'
import skandynawski_industrialny_nowoczesny from './12_skandynawski_industrialny_nowoczesny.jpg'
import skandynawski_maksymalizm_skandynawski_nowoczesny from './8_skandynawski_maksymalizm_skandynawski_nowoczesny.jpg'
import skandynawski_hygge_kolonialny from './37_skandynawski_hygge_kolonialny.jpg'
import skandynawski_hygge_nowoczesny from './38_skandynawski_hygge_nowoczesny.jpg'
import skandynawski_marynistyczny_nowoczesny from './53_skandynawski_marynistyczny_nowoczesny.jpg'
import skandynawski_rustykalny_nowoczesny from './55_skandynawski_rustykalny_nowoczesny.jpg'
import skandynawski_prowansalski_minimalistyczny from './26_skandynawski_prowansalski_minimalistyczny.jpg'
import skandynawski_minimalistyczny_rustykalny from './40_skandynawski_minimalistyczny_rustykalny.jpg'
import skandynawski_nowoczesny_hygge from './43_skandynawski_nowoczesny_hygge.jpg'

// Mid century
import midcentury_modern_boho_kolonialny from './56_midcentury_modern_boho_kolonialny.jpg'
import midcentury_modern_skandynawski_nowoczesny from './5_midcentury_modern_skandynawski_nowoczesny.jpg'
import midcentury_modern_industrialny_nowoczesny from './9_midcentury_modern_industrialny_nowoczesny.jpg'
import midcentury_modern_nowoczesny_minimalistyczny from './14_midcentury_modern_nowoczesny_minimalistyczny.jpg'
import midcentury_modern_industrialny_kolonialny from './33_midcentury_modern_industrialny_kolonialny.jpg'
import midcentury_modern_art_deco_nowoczesny from './47_midcentury_modern_art_deco_nowoczesny.jpg'
import midcentury_modern_glamour_nowoczesny from './62_mid_century_modern_glamour_nowoczesny.jpg'

// Hampton
import hampton_marynistyczny_prowansalski from './2_hampton_marynistyczny_prowansalski.jpg'

// Industrialny
import industrialny_minimalistyczny_skandynawski from './3_industrialny_minimalistyczny_skandynawski.jpg'
import industrialny_skandynawski_retro from './20_industrialny_skandynawski_retro.jpg'
import industrialny_prowansalski_nowoczesny from './57_industrialny_prowansalski_nowoczesny.jpg'
import industrialny_minimalistyczny_nowoczesny from './63_industrialny_minimalistyczny_nowoczesny.jpg'

// Kolonialny
import kolonialny_rustykalny_hygge from './4_kolonialny_rustykalny_hygge.jpg'
import kolonialny_skandynawski_nowoczesny from './32_kolonialny_skandynawski_nowoczesny.jpg'

// Minimalistyczny
import minimalistyczny_skandynawski_nowoczesny from './7_minimalistyczny_skandynawski_nowoczesny.jpg'
import minimalistyczny_nowoczesny_industrialny from './19_minimalistyczny_nowoczesny_industrialny.jpg'
import minimalistyczny_klasyczny_glamour from './24_minimalistyczny_klasyczny_glamour.jpg'
import minimalistyczny_nowoczesny_midcentury_modern from './46_minimalistyczny_nowoczesny_midcentury_modern.jpg'
import minimalistyczny_midcentury_modern_skandynawski from './50_minimalistyczny_mid_century_modern_skandynawski.jpg'
import minimalistyczny_industrialny_midcentury_modern from './64_minimalistyczny_industrialny_midcentury_modern.jpg'

// Retro
import retro_skandynawski_maksymalizm_skandynawski from './15_retro_skandynawski_maksymalizm_skandynawski.jpg'
import retro_skandynawski_midcentury_modern from './23_retro_skandynawski_midcentury_modern.jpg'
import retro_midcentury_modern_industrialny from './39_retro_mid_century_modern_industrialny.jpg'

// Międzynarodowy
import miedzynarodowy_skandynawski_nowoczesny from './16_miedzynarodowy_skandynawski_nowoczesny.jpg'

// Glamour
import glamour_art_deco_klasyczny from './17_glamour_art_deco_klasyczny.jpg'

// Art deco
import art_deco_nowoczesny_skandynawski from './22_art_deco_nowoczesny_skandynawski.jpg'

// Rustykalny
import rustykalny_biedermeier_klasyczny from './27_rustykalny_biedermeier_klasyczny.jpg'
import rustykalny_skandynawski from './35_rustykalny_skandynawski.jpg'

// Biedermeier
import biedermeier_kolonialny_skandynawski from './29_biedermeier_kolonialny_skandynawski.jpg'
import biedermeier_minimalistyczny_midcentury_modern from './30_biedermeier_minimalistyczny_midcentury_modern.jpg'

// Klasyczny
import klasyczny_glamour_biedermeier from './31_klasyczny_glamour_biedermeier.jpg'
import klasyczny_glamour_art_deco from './59_klasyczny_glamour_art_deco.jpg'
import klasyczny_midcentury_modern_biedermeier from './60_klasyczny_midcentury_modern_biedermeier.jpg'

// Prowansalski
import prowansalski_klasyczny_biedermeier from './36_prowansalski_klasyczny_biedermeier.jpg'
import prowansalski_skandynawski_nowoczesny from './52_prowansalski_skandynawski_nowoczesny.jpg'

const boho = 'Boho';
const nowoczesny = 'Nowoczesny';
const skandynawski = 'Skandynawski';
const skandynawskiMaksymalizm = 'Skandynawski maksymalizm';
const midCentury = 'Mid century modern';
const hampton = 'Hampton';
const industrialny = 'Industrialny';
const kolonialny = 'Kolonialny';
const minimalistyczny = 'Minimalistyczny';
const retro = 'Retro';
const glamour = 'Glamour';
const artDeco = 'Art deco';
const rustykalny = 'Rustykalny';
const biedermeier = 'Biedermeier';
const klasyczny = 'Klasyczny';
const prowansalski = 'Prowansalski';
const hygge = 'Hygge';
const miedzynarodowy = 'Miedzynarodowy';
const marynistyczny = 'Marynistyczny';

type Styles =
    | 'Boho'
    | 'Nowoczesny'
    | 'Skandynawski'
    | 'Mid century'
    | 'Hampton'
    | 'Industrialny'
    | 'Kolonialny'
    | 'Minimalistyczny'
    | 'Retro'
    | 'Międzynarodowy'
    | 'Glamour'
    | 'Art deco'
    | 'Rustykalny'
    | 'Biedermeier'
    | 'Klasyczny'
    | 'Prowansalski'

export const descriptions: { [key: string]: string } = {
    'Boho': 'I to się nam podoba. Mamy przyjemność z kolorową duszyczką. Nie lubisz nudy, żyjesz z przekonaniem że masz wpływ na swoje najbliższe otocznie, dlatego nie wybierasz monotonii i szarości. Pasują do Ciebie kompozycje kolorystyczne i odważne różnorodne wzory: etniczne, kwiatowe, folkowe, a nawet geometryczne . Drzemie w Tobie coś z hipisa i miłośnika artystycznej bohemy, a to dobry znak, bo będziesz dążyć do niepowtarzalnej przestrzeni.',
    'Nowoczesny': 'Światło i otwarta przestrzeń ponad wszystko. Twoje nowoczesne upodobania przejawiać się mogą w zastosowanych materiałach jak szkło, beton, metal, rzadziej drewno, a jeśli już to jasne. Paleta barw stawia na chłodne odcienie. Prostota, brak ornamentów i dekoracyjnych drobiazgów, najważniejsze są formy mebli, materiały i funkcjonalne rozwiązania. Dobrze wybrałeś, lubisz styl ponadczasowy, który od ponad 100 lat cieszy się ogromnym powodzeniem i nic nie wskazuje na to że coś może zstąpić jego miejsce.',
    'Skandynawski': 'Okazuje się że nie tylko na zewnątrz, możesz odpocząć w zgodzie z naturą. Twoje upodobania do stylu skandynawskiego, pozwolą Ci się cieszyć naturalnymi materiałem, jasnością i przestronnością nawet kiedy jesteś we wnętrzu. Tutaj nic nie jest dziełem przypadku, dzięki czemu styl skandynawski słynie z funkcjonalnych rozwiązań. Na palecie kolorystycznej dominuje biel, szarość, które mogą być pomieszane z ecrue, wrzosem, błękitem i zielenią. Meble lekkie o uproszczonej geometrii sprawią, że wnętrze sprzyjać będzie odpoczynkowi i skupieniu.',
    'Mid century modern': 'Masz wyrafinowany gust, czy wiesz że styl w jakim jest Twoje ulubione wnętrze ukształtował się w legendarnej szkole designu Bauhaus.  Nadrzędna funkcja Midcentury Modern to praktyczność, ergonomia i wytrzymałość. Słynie z nowoczesnych rozwiązań i unikatowego designu mebli. Na pierwszy rzut oka można rzec o takim wnętrzu less is more, ale dzięki temu że styl sugeruje połączenie różnorodnych kolorów, tekstur, to stanowczo nie umieścimy go w szufladce z nazwą "monotonny".',
    'Hampton': '',
    'Industrialny': 'Czy wiesz, że najwięcej znanych architektów projektuje swoje domy w stylu industrialnym?  Przygodę z tym ponadczasowym stylem rozpocznijmy od wizyty w starej fabryce, bo najwięcej inspiracji możemy uzyskać właśnie z tego wnętrza. Duża ilości światła, otwarta przestrzeń i  szczerość materiału. Zapewne jesteś odważny, bo odkryta cegła nie schowana pod warstwą tynku lub pomalowany podciąg stalowy i wyeksponowany w salonie to dla Ciebie nie problem. Metal, stal, szkło, beton i drewno z historią, to wszystko co może pojawić się w Twoim wnętrzu.',
    'Kolonialny': 'Zapewne mamy przyjemność z miłośnikiem podróży. Pamiątki z wyjazdów o odległej szerokości geograficznej jak Afryka i Azja idealnie wkomponują się w Twoją ulubioną przestrzeń. Formy mebli o prostych kształtach, ale z egzotycznymi dodatkami.',
    'Minimalistyczny': 'Bez zbędnych dekoracji i "przegadania" treścią. Przestrzeń i harmonia to cechy Twojego idealnego stylu.  Cenisz porządek, a zagracona przestrzeń i odkryte półki z bibelotami nie sprzyjają Twojemu dobremu nastrojowi. Wszystkie możliwe akcesoria i sprzęty, schowane w różnego rodzaju zabudowach, sprawiają że Twoje wnętrze optycznie jest powiększone.',
    'Retro': 'Ktoś tu chyba lubi przenosić się w czasie do lat 50 i 60 tych. Ale zaraz, zaraz nie utożsamiaj tego wnętrza z historią, bo co prawda nawiązuje do przeszłości to przede wszystkim łączy ją z nowoczesnymi elementami. Jeśli na poddaszu Twoich rodziców bądź dziadków stoją zakurzone drewniane meble na nóżkach to nie możesz pozostawić ich bez renowacji. W połączeniu z chromowanymi dodatkami, stylizowanymi zegarami, wzorzystymi motywami stworzą piękną kompozycję. Wyraziste kolory zestawione z szarościami i beżami będą wspaniałym tłem dla eksponowania różnych sprzętów i akcesoriów. Idealnie nada się do tego analogowy aparat, radio z lat 50tych, ale nawet kolorowy toster lub wolnostojąca lodówka.',
    'Glamour': 'Magia i blask, z tym powinno utożsamiane być wnętrze, które aranżujesz. Szczypta kryształów, błyszczących tkanin, w kompozycji z barokowymi meblami, tworzy coś co zwolennicy stylu glamour określają mianem "wytworne". Tajemnica wnętrza glamour tkwi w zestawieniach tego co majestatyczne, z tym co lekkie i nowoczesne.',
    'Art deco': 'Widzę, że lubisz luksus, ale jeszcze bardziej awangardę. Perfekcjonizm i elegancja, to coś czego nie powinno zabraknąć w Twoim otoczeniu. By wydobyć te cechy, nie zapomnij o kolorach. Ecrue, biel, szarość, beż, brąz i klasyczna czerń, pozwolą Ci stworzyć wymarzony styl. Zaczekaj! Widzę, że nie lubisz nudy we wnętrzu. Geometryczne kształty, różnorodne struktury,  w odpowiednich proporcjach to coś właśnie w Twoim stylu.',
    'Rustykalny': 'Przenieśmy się do szlacheckiego dworu lub wiejskiej tradycyjnej chaty, bo zdaje się, że tam kierują się Twoje myśli kiedy myślisz o wnętrzu idealnym. Pod stopami skrzypiące drewno, na suficie  drewniane belki, z wyraźnymi słojami i sękami, lniane zasłony i kolory ziemi. Naturalne, tradycyjne wnętrze szlacheckiego dworku, sprawia że poczuć się można jak w sielance.',
    'Biedermeier': 'Dwory i pałace to miejsca z których możesz czerpać inspiracje. Dominantą wnętrza w stylu biedermeier są meble o eleganckim kształcie i polerowanej powierzchni, portale kominkowe i obrazy w antycznych ramach.  Charakter przestrzeni jest przytulny dzięki wykorzystaniu ciepłych barw i utożsamić go można z ogniskiem rodzinnym.',
    'Klasyczny': 'Mówią, że to co klasyczne jest ponadczasowe i eleganckie. Tak też jest w tym przypadku. Wygoda i dobry smak to coś co charakteryzuje wnętrze w stylu klasycznym. Należy pamiętać o symetrii i neutralnych barwach, a najbardziej poszaleć można przy różnorodności zastosowanych materiałów jak użycie drewna, kamienia i skór. Stylizację w stylu klasycznym podkreślają kryształowe żyrandole i lustra w rzeźbionych ramach.',
    'Prowansalski': 'Sielski klimat francuskiej wsi, domy z naturalnego kamienia, pola lawendy, to miejsca, które bliskie są Twojej duszy. Meble z historią lub stylizowane na starsze, na tle bieli, pastelowych odcieni, naturalnych materiałów, z kamienia i drewna wyartykułują prowansalski charakter.',
    'maksymalizm': 'Jesteś na czasie. Twój styl to rewolucja we wnętrzach. W roku 2019 do wnętrz zawitał skandynawski maksymalizm, któremu nie są obce intensywne  kolory jak szmaragd, fuksja, fiolet i granat. Kolory to jeszcze nie wszystko, bo zestawione są ze wzorami palmowych liści, pędami bananowca, a czasem nawet złotymi dodatkami. Wśród miękkich tkanin aksamitu i weluru to sama przyjemność obcowania w takim wnętrzu.',
    'Hygge': 'Przytulność i ciepło domowego ogniska to przymioty z których słynie styl hygge. Twoje upodobania do ulubionego stylu Duńczyków  nie mogły by się udać bez akcentów poduszek w jasnych kolorach, koców, kominka na tle drewna i bieli.',
    'Miedzynarodowy': 'Odmienność, oryginalność, dalekie szerokości geograficzne to wszystko w zasięgu ręki, w Twoim ulubionym wnętrzu. Sztuka łączenia materiałów i kolorów dopracowana do perfekcji np. w typowym wnętrzu wietnamskim, indyjskim, marokańskim lub czasem bardziej powściągliwie jak we wnętrzu japońskim. Nie polega tpo na wiernym odtworzeniu dalekich kultur, a świadomym doborze, inspiracji tym co obce i połączenie tego z tym co współczesne i europejskie.',
    'Marynistyczny': 'Świeżość kolorów, prostota mebli i intencjonalna niedoskonałość, to wszystko powinno znaleźć się w Twoim wnętrzu. Pamiątki znad morza, drewniane wiosła, muszle, latarnie morskie powinny pojawić się na Twoich półkach i regałach, a kajuta na luksusowym jachcie, to inspiracja dla Twojego wnętrza.'
};

export const wages: { [key: string]: { first: string; second: string; third?: string; fourth?: string} } = {
    boho_midcentury_retro: {
        first: boho,
        second: midCentury,
        third: retro,
    },
    boho_nowoczesny_rustykalny: {
        first: boho,
        second: nowoczesny,
        third: rustykalny
    },

    nowoczesny_boho_midcentury_modern: {
        first: nowoczesny,
        second: boho,
        third: midCentury
    },
    nowoczesny_skandynawski_boho: {
        first: nowoczesny,
        second: skandynawski,
        third: boho
    },
    nowoczesny_kolonialny_rustykalny: {
        first: nowoczesny,
        second: kolonialny,
        third: rustykalny,
    },
    nowoczesny_minimalistyczny_skandynawski: {
        first: nowoczesny,
        second: minimalistyczny,
        third: skandynawski
    },
    nowoczesny_minimalistyczny_midcentury_modern: {
        first: nowoczesny,
        second: minimalistyczny,
        third: midCentury,
    },
    nowoczesny_industrialny_midcentury_modern: {
        first: nowoczesny,
        second: industrialny,
        third: midCentury,
    },
    nowoczesny_minimalistycznyny_klasyczny: {
        first: nowoczesny,
        second: minimalistyczny,
        third: klasyczny
    },

    skandynawski_maksymalizm_boho_nowoczesny: {
        first: skandynawskiMaksymalizm,
        second: boho,
        third: nowoczesny,
    },
    skandynawski_industrialny_nowoczesny: {
        first: skandynawski,
        second: industrialny,
        third: nowoczesny,
    },
    skandynawski_rustykalny_kolonialny: {
        first: skandynawski,
        second: rustykalny,
        third: kolonialny,
    },
    skandynawski_maksymalizm_skandynawski_nowoczesny: {
        first: skandynawskiMaksymalizm,
        second: skandynawski,
        third: nowoczesny,
    },
    skandynawski_hygge_kolonialny: {
        first: skandynawski,
        second: hygge,
        third: kolonialny,
    },
    skandynawski_hygge_nowoczesny: {
        first: skandynawski,
        second: hygge,
        third: nowoczesny,
    },
    skandynawski_marynistyczny_nowoczesny: {
        first: skandynawski,
        second: marynistyczny,
        third: nowoczesny,
    },
    skandynawski_rustykalny_nowoczesny: {
        first: skandynawski,
        second: rustykalny,
        third: nowoczesny,
    },
    skandynawski_prowansalski_minimalistyczny: {
        first: skandynawski,
        second: prowansalski,
        third: minimalistyczny,
    },
    skandynawski_minimalistyczny_rustykalny: {
        first: skandynawski,
        second: minimalistyczny,
        third: rustykalny,
    },
    skandynawski_nowoczesny_hygge: {
        first: skandynawski,
        second: nowoczesny,
        third: hygge,
    },

    midcentury_modern_boho_kolonialny: {
        first: midCentury,
        second: boho,
        third: kolonialny,
    },
    midcentury_modern_skandynawski_nowoczesny: {
        first: midCentury,
        second: skandynawski,
        third: nowoczesny,
    },
    midcentury_modern_industrialny_nowoczesny: {
        first: midCentury,
        second: industrialny,
        third: nowoczesny,
    },
    midcentury_modern_nowoczesny_minimalistyczny: {
        first: midCentury,
        second: nowoczesny,
        third: minimalistyczny,
    },
    midcentury_modern_industrialny_kolonialny: {
        first: midCentury,
        second: industrialny,
        third: kolonialny,
    },
    midcentury_modern_art_deco_nowoczesny: {
        first: midCentury,
        second: artDeco,
        third: nowoczesny,
    },
    midcentury_modern_glamour_nowoczesny: {
        first: midCentury,
        second: glamour,
        third: nowoczesny,
    },

    hampton_marynistyczny_prowansalski: {
        first: hampton,
        second: marynistyczny,
        third: prowansalski,
    },

    industrialny_minimalistyczny_skandynawski: {
        first: industrialny,
        second: minimalistyczny,
        third: skandynawski,
    },
    industrialny_skandynawski_retro: {
        first: industrialny,
        second: skandynawski,
        third: retro,
    },
    industrialny_prowansalski_nowoczesny: {
        first: industrialny,
        second: prowansalski,
        third: nowoczesny,
    },
    industrialny_minimalistyczny_nowoczesny: {
        first: industrialny,
        second: minimalistyczny,
        third: nowoczesny,
    },

    kolonialny_rustykalny_hygge: {
        first: kolonialny,
        second: rustykalny,
        third: hygge,
    },
    kolonialny_skandynawski_nowoczesny: {
        first: kolonialny,
        second: skandynawski,
        third: nowoczesny,
    },

    minimalistyczny_skandynawski_nowoczesny: {
        first: minimalistyczny,
        second: skandynawski,
        third: nowoczesny,
    },
    minimalistyczny_nowoczesny_industrialny: {
        first: minimalistyczny,
        second: nowoczesny,
        third: industrialny,
    },
    minimalistyczny_klasyczny_glamour: {
        first: minimalistyczny,
        second: klasyczny,
        third: glamour,
    },
    minimalistyczny_nowoczesny_midcentury_modern: {
        first: minimalistyczny,
        second: nowoczesny,
        third: midCentury,
    },
    minimalistyczny_midcentury_modern_skandynawski: {
        first: minimalistyczny,
        second: midCentury,
    },
    minimalistyczny_industrialny_midcentury_modern: {
        first: minimalistyczny,
        second: industrialny,
        third: midCentury,
    },

    retro_skandynawski_maksymalizm_skandynawski: {
        first: retro,
        second: skandynawskiMaksymalizm,
        third: skandynawski
    },
    retro_skandynawski_midcentury_modern: {
        first: retro,
        second: skandynawski,
        third: midCentury,
    },
    retro_midcentury_modern_industrialny: {
        first: retro,
        second: midCentury,
        fourth: industrialny,
    },

    miedzynarodowy_skandynawski_nowoczesny: {
        first: miedzynarodowy,
        second: skandynawski,
        third: nowoczesny,
    },

    glamour_art_deco_klasyczny: {
        first: glamour,
        second: artDeco,
        third: klasyczny,
    },
    art_deco_nowoczesny_skandynawski: {
        first: glamour,
        second: nowoczesny,
        third: skandynawski,
    },

    rustykalny_biedermeier_klasyczny: {
        first: rustykalny,
        second: biedermeier,
        third: klasyczny,
    },

    biedermeier_kolonialny_skandynawski: {
        first: biedermeier,
        second: kolonialny,
        third: skandynawski,
    },
    biedermeier_minimalistyczny_midcentury_modern: {
        first: biedermeier,
        second: minimalistyczny,
        third: midCentury,
    },

    klasyczny_glamour_biedermeier: {
        first: klasyczny,
        second: glamour,
        third: biedermeier,
    },
    klasyczny_glamour_art_deco: {
        first: klasyczny,
        second: glamour,
        third: artDeco,
    },
    klasyczny_midcentury_modern_biedermeier: {
        first: klasyczny,
        second: midCentury,
        third: biedermeier,
    },

    prowansalski_klasyczny_biedermeier: {
        first: prowansalski,
        second: klasyczny,
        third: biedermeier,
    },
    prowansalski_skandynawski_nowoczesny: {
        first: prowansalski,
        second: skandynawski,
        third: nowoczesny,
    },
};

const styles = [
    {src: boho_midcentury_retro, id: 'boho_midcentury_retro'},
    {src: boho_nowoczesny_rustykalny, id: 'boho_nowoczesny_rustykalny'},
    {src: nowoczesny_boho_midcentury_modern, id: 'nowoczesny_boho_midcentury_modern'},
    {src: nowoczesny_skandynawski_boho, id: 'nowoczesny_skandynawski_boho'},
    {src: nowoczesny_kolonialny_rustykalny, id: 'nowoczesny_kolonialny_rustykalny'},
    {src: nowoczesny_minimalistyczny_skandynawski, id: 'nowoczesny_minimalistyczny_skandynawski'},
    {src: nowoczesny_minimalistyczny_midcentury_modern, id: 'nowoczesny_minimalistyczny_midcentury_modern'},
    {src: nowoczesny_industrialny_midcentury_modern, id: 'nowoczesny_industrialny_midcentury_modern'},
    {src: nowoczesny_minimalistycznyny_klasyczny, id: 'nowoczesny_minimalistycznyny_klasyczny'},
    {src: skandynawski_maksymalizm_boho_nowoczesny, id: 'skandynawski_maksymalizm_boho_nowoczesny'},
    {src: skandynawski_industrialny_nowoczesny, id: 'skandynawski_industrialny_nowoczesny'},
    {src: skandynawski_rustykalny_kolonialny, id: 'skandynawski_rustykalny_kolonialny'},
    {src: skandynawski_maksymalizm_skandynawski_nowoczesny, id: 'skandynawski_maksymalizm_skandynawski_nowoczesny'},
    {src: skandynawski_hygge_kolonialny, id: 'skandynawski_hygge_kolonialny'},
    {src: skandynawski_hygge_nowoczesny, id: 'skandynawski_hygge_nowoczesny'},
    {src: skandynawski_marynistyczny_nowoczesny, id: 'skandynawski_marynistyczny_nowoczesny'},
    {src: skandynawski_rustykalny_nowoczesny, id: 'skandynawski_rustykalny_nowoczesny'},
    {src: skandynawski_prowansalski_minimalistyczny, id: 'skandynawski_prowansalski_minimalistyczny'},
    {src: skandynawski_minimalistyczny_rustykalny, id: 'skandynawski_minimalistyczny_rustykalny'},
    {src: skandynawski_nowoczesny_hygge, id: 'skandynawski_nowoczesny_hygge'},
    {src: midcentury_modern_boho_kolonialny, id: 'midcentury_modern_boho_kolonialny'},
    {src: midcentury_modern_skandynawski_nowoczesny, id: 'midcentury_modern_skandynawski_nowoczesny'},
    {src: midcentury_modern_industrialny_nowoczesny, id: 'midcentury_modern_industrialny_nowoczesny'},
    {src: midcentury_modern_nowoczesny_minimalistyczny, id: 'midcentury_modern_nowoczesny_minimalistyczny'},
    {src: midcentury_modern_industrialny_kolonialny, id: 'midcentury_modern_industrialny_kolonialny'},
    {src: midcentury_modern_art_deco_nowoczesny, id: 'midcentury_modern_art_deco_nowoczesny'},
    {src: midcentury_modern_glamour_nowoczesny, id: 'midcentury_modern_glamour_nowoczesny'},
    {src: hampton_marynistyczny_prowansalski, id: 'hampton_marynistyczny_prowansalski'},
    {src: industrialny_minimalistyczny_skandynawski, id: 'industrialny_minimalistyczny_skandynawski'},
    {src: industrialny_skandynawski_retro, id: 'industrialny_skandynawski_retro'},
    {src: industrialny_prowansalski_nowoczesny, id: 'industrialny_prowansalski_nowoczesny'},
    {src: industrialny_minimalistyczny_nowoczesny, id: 'industrialny_minimalistyczny_nowoczesny'},
    {src: kolonialny_rustykalny_hygge, id: 'kolonialny_rustykalny_hygge'},
    {src: kolonialny_skandynawski_nowoczesny, id: 'kolonialny_skandynawski_nowoczesny'},
    {src: minimalistyczny_skandynawski_nowoczesny, id: 'minimalistyczny_skandynawski_nowoczesny'},
    {src: minimalistyczny_nowoczesny_industrialny, id: 'minimalistyczny_nowoczesny_industrialny'},
    {src: minimalistyczny_klasyczny_glamour, id: 'minimalistyczny_klasyczny_glamour'},
    {src: minimalistyczny_nowoczesny_midcentury_modern, id: 'minimalistyczny_nowoczesny_midcentury_modern'},
    {src: minimalistyczny_midcentury_modern_skandynawski, id: 'minimalistyczny_midcentury_modern_skandynawski'},
    {src: minimalistyczny_industrialny_midcentury_modern, id: 'minimalistyczny_industrialny_midcentury_modern'},
    {src: retro_skandynawski_maksymalizm_skandynawski, id: 'retro_skandynawski_maksymalizm_skandynawski'},
    {src: retro_skandynawski_midcentury_modern, id: 'retro_skandynawski_midcentury_modern'},
    {src: retro_midcentury_modern_industrialny, id: 'retro_midcentury_modern_industrialny'},
    {src: miedzynarodowy_skandynawski_nowoczesny, id: 'miedzynarodowy_skandynawski_nowoczesny'},
    {src: glamour_art_deco_klasyczny, id: 'glamour_art_deco_klasyczny'},
    {src: art_deco_nowoczesny_skandynawski, id: 'art_deco_nowoczesny_skandynawski'},
    {src: rustykalny_biedermeier_klasyczny, id: 'rustykalny_biedermeier_klasyczny'},
    {src: biedermeier_kolonialny_skandynawski, id: 'biedermeier_kolonialny_skandynawski'},
    {src: biedermeier_minimalistyczny_midcentury_modern, id: 'biedermeier_minimalistyczny_midcentury_modern'},
    {src: klasyczny_glamour_biedermeier, id: 'klasyczny_glamour_biedermeier'},
    {src: klasyczny_glamour_art_deco, id: 'klasyczny_glamour_art_deco'},
    {src: klasyczny_midcentury_modern_biedermeier, id: 'klasyczny_midcentury_modern_biedermeier'},
    {src: prowansalski_klasyczny_biedermeier, id: 'prowansalski_klasyczny_biedermeier'},
    {src: prowansalski_skandynawski_nowoczesny, id: 'prowansalski_skandynawski_nowoczesny'},
];

export default styles;
