import React from 'react';
import './app.scss';
import { useHistory } from "react-router-dom";

interface props {
    name: string;
    setName(value: string): void;

    email: string;
    setEmail(value: string): void;

    privacyPolicy: boolean;
    setPrivacyPolicy(value: boolean): void;

    newsletter: boolean;
    setNewsletter(value: boolean): void;
}

const WelcomeForm = ({
                         name,
                         setName,
                         email,
                         setEmail,
                         privacyPolicy,
                         setPrivacyPolicy,
                         newsletter,
                         setNewsletter
                     }: props) => {
    const history = useHistory();

    function goToPlaces(ev: any) {
        ev.preventDefault();
        return history.push('places');
    }

    return (
        <form onSubmit={goToPlaces}>
            <label>Email</label>
            <input
                required
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                type="email"
                value={email}/>
            <label>Imię</label>
            <input
                required
                value={name}
                onChange={event => setName(event.target.value)}
                name="name"
                type="text"/>
            <label className="newsletter-checkbox">
                <input
                    onChange={event => setPrivacyPolicy(event.target.checked)}
                    checked={privacyPolicy}
                    name="privacy-policy"
                    required
                    type="checkbox"/>
                Akceptuję <a href="./regulamin-wnetrza-na-fali.pdf" target="_blank">regulamin</a> oraz <a href="./Klauzula-RODO.pdf" target="_blank">RODO</a>
            </label>
            <label className="newsletter-checkbox">
                <input
                    onChange={event => setNewsletter(event.target.checked)}
                    checked={newsletter}
                    name="newsletter"
                    type="checkbox"/>
                Zgadzam się na otrzymywanie newslettera
            </label>
            <input type="submit" value="Rozpocznij"/>
        </form>
    )
};

const WelcomeText = () =>
    (<div className="App__left">
        <h1>Cześć, <br/>poznajmy Twój styl</h1>
        <p>
            Odnalezienie własnego stylu i rodzaju przestrzeni nie należy do najłatwiejszych zadań, jednak z
            pomocą przychodzi nam technologia.
            Zebraliśmy listę pytań, które najczęściej zadajemy naszym klientom, dzięki którym jesteśmy w
            stanie szybko i precyzyjnie określić styl, jaki najbardziej trafi w Twój gust.
        </p>
        <p>
            Najpierw podaj nam nieco informacji o sobie
        </p>
    </div>);

const App = ({name, setName, email, setEmail, privacyPolicy, setPrivacyPolicy, newsletter, setNewsletter}: props) => {
    return (
        <div className="App__wrapper">
            <WelcomeText/>
            <div className="App__right form">
                <WelcomeForm
                    name={name}
                    setName={setName}
                    email={email}
                    setEmail={setEmail}
                    privacyPolicy={privacyPolicy}
                    setPrivacyPolicy={setPrivacyPolicy}
                    newsletter={newsletter}
                    setNewsletter={setNewsletter}
                />
            </div>
        </div>
    );
};

export default App;
