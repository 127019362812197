import React from "react";
import * as _ from "lodash";
import { shuffle } from "../../utils/shuffle";
import styles from "../../assets/imgs/styles";
import { StyleColumn } from "../style-column/style-column";
import { Link } from "react-router-dom";

const shuffledStyles = shuffle(styles);

interface props {
    selectedStyles: string[];
    name: string;
    email: string;
    privacyPolicy: boolean;
    newsletter: boolean;
    yourStyle: any;

    chooseStyle(newStyle: string): void;
}

export const Styles = ({selectedStyles, chooseStyle, name, email, yourStyle, privacyPolicy,
                           newsletter}: props) => {
    let chunkedStyles = _.chunk(shuffledStyles, Math.ceil(shuffledStyles.length / 3));
    const sendData = () => {
        const data = JSON.stringify({
            email,
            name,
            newsletter,
            "privacy-policy": privacyPolicy,
            "quiz-result": Object.values(yourStyle).join(', ')
        });

        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.responseType = "text";

        xhr.open("POST", "https://wnetrza-59de.restdb.io/rest/wnetrza");
        xhr.setRequestHeader("content-type", "application/json");
        xhr.setRequestHeader("x-apikey", "5e6645f609c313436a6a01bc");
        xhr.setRequestHeader("cache-control", "no-cache");

        xhr.send(data);
    };

    return (
        <div className="App__wrapper">
            <div className="App__left">
                <h1>Które wnętrza przypadły Ci do gustu?</h1>
                <p>Z poniższych zdjęć wybierz te
                    które najbardziej oddają ducha Twoich wymarzonych wnętrz
                </p>
                <div className='navigation'>
                    <Link to={'/places'}>
                        <button>Poprzedni krok</button>
                    </Link>
                    <Link to={'/finish'}>
                        <button onClick={sendData}>Następny krok</button>
                    </Link>
                </div>
            </div>
            <div className='App__right quiz-columns'>
                {chunkedStyles.map((chunk, index) =>
                    <StyleColumn key={index} styles={chunk} chooseStyle={chooseStyle} selectedStyles={selectedStyles}/>
                )}
            </div>
        </div>
    )
};
