import { Link } from "react-router-dom";
import React from "react";

import './navigation.css';

interface props {
    previousStep?: string;
    nextStep?: string;
}

export const Navigation = ({previousStep, nextStep}: props) =>
    <div className='navigation'>
        {previousStep && <Link to={previousStep}>
            <button>Poprzedni krok</button>
        </Link>}
        {nextStep && <Link to={nextStep}>
            <button>Następny krok</button>
        </Link>}
    </div>;
