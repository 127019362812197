import { QuizButton } from "../quiz-button/quiz-button";
import React from "react";

interface props {
    styles: any[];
    selectedStyles: string[];
    chooseStyle: any;
}

export const StyleColumn = ({styles, selectedStyles, chooseStyle}: props) =>
    <div className={'quiz-buttons'}>
        {styles.map((style) =>
            <QuizButton
                key={style.src}
                isActive={selectedStyles.indexOf(style.id) >= 0}
                onClick={chooseStyle}
                value={style.id}
                image={style.src}
                text=''/>
        )}
    </div>;
