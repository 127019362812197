import * as _ from "lodash";
import React from "react";

import places from "../../assets/imgs/places";
import { Navigation } from "../navigation/navigation";
import { PlaceColumn } from "../place-column/place-column";

interface props {
    selectedPlace: string;

    choosePlace(place: any): void;
}

export const Places = ({selectedPlace, choosePlace}: props) => {
    const chunkedPlaces = _.chunk(places, Math.ceil(places.length / 3));

    return <div className="App__wrapper">
        <div className="App__left">
            <h1>Gdybyś miał się przeprowadzić, jakie miejsce byś wybrał?</h1>
            <Navigation nextStep='/styles' previousStep='/'/>
        </div>
        <div className='App__right quiz-columns'>
            {chunkedPlaces.map((chunk, index) =>
                <PlaceColumn key={index} places={chunk} selectedPlace={selectedPlace} choosePlace={choosePlace}/>
            )}
        </div>
    </div>;
};
