import React from "react";
import './quiz-button.scss';

interface props {
    image: string;
    value: any;
    text: string;
    isActive: boolean;

    onClick(val: string): void;
}

export const QuizButton = ({image, value, text, isActive, onClick}: props) =>
    <div
        className={'quiz-button ' + (isActive ? 'active' : '')}
        onClick={() => onClick(value)}>
        <img className='quiz-button__image' key={image} src={image} alt=""/>
        {text && <span className='quiz-button__text'>{text}</span>}
    </div>;
