import { QuizButton } from "../quiz-button/quiz-button";
import React from "react";

interface props {
    places: any[];
    selectedPlace: string;
    choosePlace: any;
}

export const PlaceColumn = ({places, selectedPlace, choosePlace}: props) =>
    <div className={'quiz-buttons'}>
        {places.map((place) =>
            <QuizButton
                key={place.src}
                isActive={selectedPlace === place.name}
                onClick={choosePlace}
                value={place.name}
                image={place.src}
                text={place.name}/>
        )}
    </div>;
